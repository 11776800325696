'use client';

import { createContext, useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
function usePrevious<T>(value: T) {
  let ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
export const AppContext = createContext<{
  previousPathname?: string;
}>({});
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  let pathname = usePathname();
  let previousPathname = usePrevious(pathname);
  return <AppContext.Provider value={{
    previousPathname
  }} data-sentry-element="unknown" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
            {children}
        </AppContext.Provider>;
}